import React from 'react'

import { Footer } from '../../components/Footer'
import Header from '../../components/Header'
import { MenuItem } from '../../types'

interface Props {
  menuItems: MenuItem[]
}

export const BasicLayout: React.FC<Props> = ({ children, menuItems }) => {
  return (
    <div className="BasicLayout">
      <Header menuItems={menuItems} />

      {children}

      <Footer />
    </div>
  )
}
