import React from 'react'

export interface Slide {
  backgroundImage: string
  icon: string
  title: string
  content: string
}

export interface SlideState extends Slide {
  goBackward: () => void
  goForward: () => void
}

export const SliderContext = React.createContext<SlideState | undefined>(
  undefined,
)

interface Props {
  slides: Slide[]
}

const SLIDE_INTERVAL = 7000

export const SliderContextProvider: React.FC<Props> = ({
  children,
  slides,
}) => {
  const [slideIndex, setSlideIndex] = React.useState(0)
  const slideCount = slides.length
  const timeout = React.useRef<NodeJS.Timeout>()
  const goForward = React.useCallback(
    () => setSlideIndex((idx) => (idx + 1) % slideCount),
    [setSlideIndex, slideCount],
  )
  const goBackward = () =>
    setSlideIndex((idx) => {
      const nextIndex = idx - 1
      const maxIndex = slideCount - 1

      if (nextIndex < 0) {
        return maxIndex % slideCount
      }

      return nextIndex % slideCount
    })

  React.useEffect(() => {
    timeout.current = setTimeout(goForward, SLIDE_INTERVAL)

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [goForward, slideIndex])

  return (
    <SliderContext.Provider
      value={{ ...slides[slideIndex], goForward, goBackward }}
    >
      {children}
    </SliderContext.Provider>
  )
}
