import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'

import { API } from '../../api'
import { actions as errorActions } from '../error'
import {
  ApiResult,
  LogisticsPartner,
  ParsedMarketingItem,
  ProductType,
  RootState,
  SettingsApiData,
} from '../../types'
import { getMessageFromError } from '../../utils'
import { locale } from '../../locale'

// Actions
const LOAD_SETTINGS = 'settings/load'
export const load = createAsyncThunk(LOAD_SETTINGS, async (_, { dispatch }) => {
  try {
    const response = await API.settings.load()

    return response.data
  } catch (error) {
    dispatch(errorActions.setErrorMessage(getMessageFromError(error)))
    throw error
  }
})

// Selectors
const getAgreements = (state: RootState) => state.settings.agreement
const getDefaultPickupWindow = (state: RootState): [string, string] => [
  state.settings.earliest_time_default,
  state.settings.latest_time_default,
]
const getLogisticsPartners = (state: RootState) => state.settings.partner
const getMarketingsItems = (state: RootState): ParsedMarketingItem[] => {
  const text = getText(state)

  return state.settings.marketing.map((marketingText) => ({
    icon: marketingText.icon,
    heading: text[marketingText.heading_text_id][locale],
    text: text[marketingText.text_id][locale],
  }))
}
const getMenuItems = (state: RootState) => state.settings.menu
const getPointsOfPickup = (state: RootState) => state.settings.points_of_pickup
const getProducts = (state: RootState) => state.settings.product
const getCallTagProducts = createSelector(getProducts, (products) =>
  products.filter((product) => product.type === ProductType.CALL_TAG),
)
const getLabelProducts = createSelector(getProducts, (products) =>
  products.filter((product) => product.type === ProductType.LABEL),
)
const getLabelIdToCallTagIdMap = createSelector(
  [getCallTagProducts, getLabelProducts],
  (callTagProducts, labelProducts) => {
    return callTagProducts.reduce<Record<number, number>>(
      (map, callTagProduct) => {
        const labelProductForCallTag = labelProducts.find(
          (product) => product.service_type === callTagProduct.service_type,
        )

        if (labelProductForCallTag) {
          map[labelProductForCallTag.product_id] = callTagProduct.product_id
        }

        return map
      },
      {},
    )
  },
)
const getSettings = (state: RootState) => state.settings
const getStates = (state: RootState) => state.settings.state
const getText = (state: RootState) => state.settings.text
const getUPSPartner = createSelector(getLogisticsPartners, (partners) =>
  partners.find((partner) => partner.name === 'UPS'),
)
const getUPSLabelProduct = createSelector(
  [getProducts, getUPSPartner],
  (products, upsPartner) => {
    let upsLabelProduct = undefined
    if (upsPartner) {
      upsLabelProduct = products.find(
        (product) =>
          product.partner_id === upsPartner.partner_id &&
          product.type === ProductType.LABEL,
      )
    }
    return upsLabelProduct
  },
)
const getValuePropositions = (state: RootState) =>
  state.settings.value_proposition

export const selectors = {
  getAgreements,
  getCallTagProducts,
  getDefaultPickupWindow,
  getLabelIdToCallTagIdMap,
  getLabelProducts,
  getLogisticsPartners,
  getMarketingsItems,
  getMenuItems,
  getPointsOfPickup,
  getProducts,
  getSettings,
  getStates,
  getText,
  getUPSLabelProduct,
  getUPSPartner,
  getValuePropositions,
}

// Slice
export const initialState: SettingsApiData = {
  agreement: [],
  earliest_time_default: '',
  latest_time_default: '',
  marketing: [],
  menu: [],
  partner: [],
  points_of_pickup: [],
  product: [],
  result: ApiResult.INITIAL,
  state: [],
  text: {},
  value_proposition: [],
}

export const { actions, reducer } = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(load.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      partner: action.payload.partner.map((partner: LogisticsPartner) => ({
        ...partner,
        service_types: [],
      })),
    }))
  },
})
