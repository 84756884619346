import axios from 'axios'

import { ApiPromiseResponse } from '../types'

const username = process.env.REACT_APP_API_KEY || ''
export const BASIC_AUTH = {
  auth: { username, password: '' },
}

interface HttpClient {
  get: <T = object, R = any>(
    endpoint: string,
    params: T,
  ) => ApiPromiseResponse<R>
  post: <T = object, R = any>(
    endpoint: string,
    params: T,
  ) => ApiPromiseResponse<R>
}

export const unauthenticated: HttpClient = {
  get: (endpoint, params) => axios.get(endpoint, { params, ...BASIC_AUTH }),
  post: (endpoint, params) => axios.post(endpoint, params, BASIC_AUTH),
}
