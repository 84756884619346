import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actions as errorActions } from '../../redux/error/'
import {
  actions as modalActions,
  selectors as modalSelectors,
} from '../../redux/modal/'
import { selectors as authorizationTimeoutSelectors } from '../../redux/authorizationTimeout'
import { AppDispatch, ModalName } from '../../types'
import Banner from '../../components/Banner'
import './ModalContainer.scss'

interface Props {
  children: (closeModalContainer: () => void) => React.ReactNode
  modalName: ModalName
  onClose?: () => void
}

const ESCAPE_KEY = 'Escape'

export const ModalContainer: React.FC<Props> = ({ onClose, ...props }) => {
  const reauthorizationRequired = useSelector(
    authorizationTimeoutSelectors.getAuthorizationTimeout,
  )
  const dispatch = useDispatch<AppDispatch>()
  const closeModal = React.useCallback(() => {
    if (!reauthorizationRequired) {
      dispatch(errorActions.clearError())
      dispatch(modalActions.setOpenModalName(ModalName.NONE))
      if (onClose) onClose()
    }
  }, [dispatch, onClose, reauthorizationRequired])
  const clickOutside = React.useCallback(
    ({ target }: any) => {
      if (!target.closest('#modal')) closeModal()
    },
    [closeModal],
  )
  const openModalName = useSelector(modalSelectors.getOpenModalName)
  const isOpen = openModalName === props.modalName
  const handleKeyDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === ESCAPE_KEY) {
        closeModal()
      }
    },
    [closeModal],
  )

  React.useEffect(() => {
    const documentHeight = document.documentElement.scrollHeight
    const windowHeight = window.innerHeight
    const scrollbarPresent = documentHeight > windowHeight

    if (isOpen) {
      document.addEventListener('click', clickOutside)
      document.addEventListener('keydown', handleKeyDown)
      if (scrollbarPresent) {
        document.body.classList.add('no-scroll')
      }
    }

    return () => {
      document.removeEventListener('click', clickOutside)
      document.removeEventListener('keydown', handleKeyDown)
      if (scrollbarPresent) {
        document.body.classList.remove('no-scroll')
      }
    }
  }, [isOpen, clickOutside, closeModal, handleKeyDown])

  if (!isOpen) return null

  return (
    <div className="ModalContainer">
      <div id="modal">
        <Banner inModal />
        {props.children(closeModal)}
      </div>
    </div>
  )
}
