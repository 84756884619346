import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Banner from '../../components/Banner'
import { buildValuePropSlides } from '../../utils/buildValuePropSlides'
import Loader from '../../components/Loader'
import { PrimaryLayout } from './PrimaryLayout'
import PrivacyPolicyModal from '../../modals/PrivacyPolicyModal'
import { routes } from '../../routes'
import { RouteTransition } from '../../components/RouteTransition'
import { selectors } from '../../redux/settings'
import { SliderContextProvider } from '../../contexts/sliderContext'
import TermsAndConditionsModal from '../../modals/TermsAndConditionsModal'
import './PrimaryLayout.scss'

export { Card } from './Card'

const TABLET_WIDTH = 768 // from styleguide.scss

const PrimaryLayoutContainer: React.FC = ({ children }) => {
  const location = useLocation()
  const isMobile = window.innerWidth < TABLET_WIDTH
  const displayBottomContent =
    !isMobile || (isMobile && location.pathname === routes.home)
  const marketingItems = useSelector(selectors.getMarketingsItems)
  const menuItems = useSelector(selectors.getMenuItems)
  const valuePropositions = useSelector(selectors.getValuePropositions)
  const i18nTextMap = useSelector(selectors.getText)
  const slides = buildValuePropSlides(valuePropositions, i18nTextMap)

  return (
    <>
      <SliderContextProvider slides={slides}>
        <PrimaryLayout
          displayBottomContent={displayBottomContent}
          marketingItems={marketingItems}
          menuItems={menuItems}
        >
          {children}
        </PrimaryLayout>
      </SliderContextProvider>

      <RouteTransition />

      <Banner />

      <Loader />

      <TermsAndConditionsModal />

      <PrivacyPolicyModal />
    </>
  )
}

export default PrimaryLayoutContainer
