import {
  CallTagResponse,
  LabelResponse,
  PickupResponse,
  TrackingResponse,
} from '../types'

export const isCallTagResponse = (
  product: CallTagResponse | LabelResponse | TrackingResponse,
): product is CallTagResponse => {
  return (
    product.product_id !== undefined &&
    !isLabelResponse(product) &&
    !isTrackingResponse(product)
  )
}

export const isLabelResponse = (
  product: LabelResponse | PickupResponse | TrackingResponse,
): product is LabelResponse => {
  return (
    (product as LabelResponse).count !== undefined ||
    Boolean((product as LabelResponse).label)
  )
}

export const isPickupResponse = (
  product: LabelResponse | PickupResponse,
): product is PickupResponse => {
  return Boolean((product as PickupResponse).pickup_request)
}

export const isTrackingResponse = (
  product: LabelResponse | TrackingResponse,
): product is TrackingResponse => {
  return Boolean((product as TrackingResponse).track)
}
