import React from 'react'
import classnames from 'classnames'

import './Drawer.scss'

export interface Props {
  visible: boolean
}

export const Drawer: React.FC<Props> = ({ visible, children }) => (
  <div
    aria-hidden={!visible}
    className={classnames('ModalDrawer-wrapper', {
      'ModalDrawer-wrapper--visible': visible,
    })}
  >
    {children}
  </div>
)
