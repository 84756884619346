import { AxiosResponse, AxiosError } from 'axios'

import { PaymentProcessors } from './environments'
import { rootReducer, store } from './redux/store'

export type ApiPromiseResponse<T> = Promise<AxiosResponse<T>>

export interface ServiceType {
  id: string
  name: string
  price: number
}

export interface LogisticsPartner {
  logo: string
  name: 'UPS' | 'FedEx'
  partner_id: number
  service_types: ServiceType[]
}

interface UsState {
  name: string
  state: string
}

export interface PointOfPickup {
  id: string
  value: string
}

export interface PickupDate {
  label: string
  value: string
}

export interface MenuItem {
  menu_id: number
  color: string
  description: string
  icon: string
}

export interface MarketingItem {
  heading_text_id: string
  icon: string
  text_id: string
}

export interface ParsedMarketingItem {
  heading: string
  icon: string
  text: string
}

export interface I18nText {
  en: string
}

export interface AuthorizationApiData {
  result: ApiResult
}

export interface ValueProposition {
  background: string
  heading_text_id: string
  icon: string
  text_id: string
}

export interface SettingsApiData {
  agreement: string[]
  earliest_time_default: string
  latest_time_default: string
  marketing: MarketingItem[]
  menu: MenuItem[]
  partner: LogisticsPartner[]
  points_of_pickup: PointOfPickup[]
  product: Product[]
  result: ApiResult
  state: UsState[]
  text: {
    [s: string]: I18nText
  }
  value_proposition: ValueProposition[]
}

export interface Product {
  icon?: string
  product_id: number
  partner_id: number
  description: string
  service_type: string
  type: ProductType
  default: boolean
}

export interface PricedProduct extends Product {
  discount_price: number
  price: number
  product_identifier: string
}

export interface DateProductAvailability {
  date: string
  display_date: string
  product_ids: number[]
}

export interface GetAvailabilityApiData {
  result: ApiResult
  availability: {
    product_ids: number[]
    dates: DateProductAvailability[]
  }
}

export interface ValidateAddressApiData {
  address: Address
  allow_override: 1 | 0
  result: ApiResult
}

export type ProductPriceMap = {
  [k: string]: Price[]
}

export type GetPricesThunkPayload = [true, ProductPriceMap] | [false, string]

export type ProductIdentifier = string

export interface Package {
  availablePickupProductIDs: number[]
  callTag: {
    product_id: number
  }
  label: Label
  labelOnly: boolean
  name: string
  packageNumber: number
  pickup: Pickup
  pickupDateAvailability: DateProductAvailability[]
  pickupWindow?: [string, string]
  tracking: {
    created_at: string
    error?: string
    product_id: number
    tracking_number: string
  }
}

export interface Order {
  packages: Package[]
  productPriceMap: ProductPriceMap
  promo_code: string
}

export type SendiumAxiosError = AxiosError<{ error: string }>

export enum SendiumErrorMessages {
  INVALID_AUTHENTICATION_TOKEN = 'Invalid authentication token',
  INVALID_PROMO_CODE = 'Invalid promo code',
  ORDER_NOT_FULFILLED = 'Order confirmed but not fulfilled due to issues with provider',
}

export interface Pickup {
  location: Location
  partner_id: number
  pickup_date: string | undefined
  point_of_pickup: PointOfPickup | undefined
  product_id: number
  special_instructions: string
  tracking_number: string
  status: string
}

export interface CallTagProductParam {
  product_id: number
}

export interface PickupProductParam {
  product_id: number
  pickup_request: PickupRequestParam
}

export interface PartnerParam {
  id: number
  service_type: string
}

export interface NestedLabelProductParam {
  label: Omit<LabelRequestParam, 'pickup_location'>
  product_id: number
}

export interface PickupRequestParam {
  earliest_time: string
  latest_time: string
  pickup_location: LocationRequestParam
  pickup_date: string
  point_of_pickup: string
  product: Array<
    CallTagProductParam | NestedLabelProductParam | TrackProductParam
  >
  special_instructions: string
}

export interface Label {
  delivery_location: Location
  dimensions: {
    height: number
    length: number
    width: number
  }
  name: string
  package_type: PackageType
  partner_id: number
  pickup_location: Location
  product_id: number
  status: string
  weight: number
}

export interface RescheduleOrderRequestParams {
  job_id: string
  product: {
    product_id: number
    identifier: string
    pickup_date: string
  }[]
  token: string
}

export interface LabelProductParam {
  label: LabelRequestParam
  product_id: number
}

export interface LabelRequestParam
  extends Omit<
    Label,
    | 'pickup_location'
    | 'delivery_location'
    | 'partner_id'
    | 'product_id'
    | 'status'
  > {
  pickup_location: LocationRequestParam
  delivery_location: LocationRequestParam
}

export interface TrackProductParam {
  track: TrackRequestParam
  product_id: number
}

export interface TrackRequestParam {
  name: string
  tracking_number: string
}

export enum PackageType {
  CUSTOM = 1,
}

export interface Address {
  address_id?: number
  address1: string
  address2?: string
  city: string
  postal_code: string
  residence?: number
  state_province: string
}

export interface Contact {
  email: string
  first_name: string
  last_name: string
  phone: string
}

export interface LocationRequestParam extends Omit<Location, 'address'> {
  address_id: number
}

export interface Location {
  address: Address
  company?: string
  contact: Contact
}

export enum ApiResult {
  'FAILURE' = 'Failure',
  'INITIAL' = '',
  'SUCCESS' = 'Success',
}

export type CancelProductParam = {
  identifier: number
  product_id: number
}

export type CancelOrderRequestParams = {
  job_id: string
  product: Array<CancelProductParam>
  token: string
}

export type CreateOrderRequestParams = {
  payment_processor: PaymentProcessors
  product: Array<CallTagProductParam | PickupProductParam | LabelProductParam>
  promo_code?: string
  token: string
}

export type EmailOrderRequestParams = {
  email: string
  token: string
  unique_id: string
}

export interface GetPricesRequestParams {
  action: 'get_prices'
  product: Array<
    GetCallTagPriceParam | GetLabelPriceParam | GetPickupPriceParam
  >
  promo_code: string
  all_required?: string
}

export interface GetCallTagPriceParam {
  pickup_address_id: number
  product_id: number
}

export interface GetLabelPriceParam {
  product_id: number
  pickup_address_id: number
  destination_address_id: number
  dimensions: {
    height: number
    length: number
    width: number
  }
  quantity: number
  weight: number
}

export interface GetPickupPriceParam {
  product_id: number
  pickup_address_id: number
  quantity: number
  pickup_date: string
}

type ApiRequestParams<T> = { entity: string; method: string; params: T[] }
export type ApiWithGlobalErrorHandler = <T = any, R = any>(
  params: ApiRequestParams<T>,
) => Promise<R>

export interface Price {
  discount_price: number
  price: number
  quantity: {
    from: number
    to: number
  }
}

export interface ProductPrice {
  pickup_address_id: string
  prices: Price[]
  product_id: number
}

export interface SendPhoneCodeForOrderApiData {
  digits: string
  result: ApiResult
}

export interface VerifyPhoneCodeApiData {
  result: ApiResult
  token: string
}

export type GetPricesResponse = ApiPromiseResponse<{
  result: ApiResult
  product: ProductPrice[]
}>
export type GetAuthorizationResponse = ApiPromiseResponse<AuthorizationApiData>
export type GetSettingsResponse = ApiPromiseResponse<SettingsApiData>
export type GetAddressResponse = ApiPromiseResponse<ValidateAddressApiData>
export type GetAvailabilityResponse = ApiPromiseResponse<GetAvailabilityApiData>
export type SendPhoneCodeForOrderResponse = ApiPromiseResponse<SendPhoneCodeForOrderApiData>
export type SendPhoneCodeResponse = ApiPromiseResponse<{ result: ApiResult }>
export type VerifyPhoneCodeResponse = ApiPromiseResponse<VerifyPhoneCodeApiData>
export type CreateOrderResponse = ApiPromiseResponse<{
  result: ApiResult
  job_id: string
  secret: string
}>
export type ConfirmOrderResponse = ApiPromiseResponse<{
  result: ApiResult
  unique_id: string
  request_number: string
}>
export type LoadOrderResponse = ApiPromiseResponse<OrderResponse>

export interface OrderResponse {
  create_date: string
  error?: string
  job_id: string
  price?: number
  product: Array<LabelResponse | PickupResponse>
  result: ApiResult
}

export interface CallTagResponse {
  price?: number
  product_id: number
}

export interface LabelResponseLabel {
  allow_cancel: number
  delivery_date: string
  delivery_location: Location
  dimensions: {
    height: string
    length: string
    width: string
  }
  name: string
  package_type: PackageType
  pickup_location: Location
  status: string
  tracking_id?: number
  tracking_number: string
  tracking_url: string
  weight: string
}

export interface LabelResponse {
  count?: number
  label?: LabelResponseLabel
  price?: number
  product_id: number
}

export interface PickupResponsePickupRequest {
  allow_cancel: number
  availability?: DateProductAvailability[]
  earliest_time: string
  latest_time: string
  pickup_date: string
  pickup_location?: Location
  point_of_pickup?: string
  product: Array<CallTagResponse | LabelResponse | TrackingResponse>
  request_id: string
  special_instructions?: string
  status: string
}

export interface TrackingResponseTrackingInfo {
  allow_cancel: number
  delivery_date: string
  name: string
  status: string | null
  tracking_id: number
  tracking_number: string
  tracking_url: string
}

export interface TrackingResponse {
  price?: number
  product_id: number
  track: TrackingResponseTrackingInfo
}

export interface PickupResponse {
  pickup_request: PickupResponsePickupRequest
  product_id: number
  price?: number
}

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>

export interface VerifedAddressState {
  [s: string]: Address
}

export enum OverrideAddress {
  'CHECKED' = '1',
  'UNCHECKED' = '0',
}

export enum AllowOverride {
  'ALLOWED' = 1,
  'DISALLOWED' = 0,
  'NOT_SET' = 2,
}

export enum AgreeToTermsAndConditions {
  'CHECKED' = '1',
  'UNCHECKED' = '0',
}

export enum ProductType {
  CALL_TAG = 'call_tag',
  PICKUP = 'Logistics Pickup',
  LABEL = 'waybill',
  TRACK = 'track',
}

export interface FormEnhancedLocation extends Location {
  meta: { override_address: OverrideAddress }
}

export interface ServerAddressError {
  allow_override: AllowOverride
  error: string
}

export interface SelectOption {
  label: string
  value: string
}

export enum ServiceOption {
  'PICKUP' = 'pickup',
  'BOTH' = 'both',
  'NONE' = '',
}

export interface ModalState {
  openModalName: ModalName
}

export type AuthorizationState = {
  phone: string
  token: string
}
export type AuthorizationTimeoutState = boolean
export type ErrorState = string
export type LoadingState = boolean

export enum ModalName {
  NONE,
  AGREEMENT,
  TERMS_AND_CONDITIONS,
  DIMENSIONS,
  CONFIRM,
  PHONE_NUMBER,
  CODE_VERIFICATION,
  LABEL_DETAILS,
  PRIVACY_POLICY,
  AUTHORIZATION_TIMEOUT,
  HOW_TO_PRINT_ANDROID,
  HOW_TO_PRINT_IOS,
  PACKAGE_DETAILS,
  FIND_TRACKING_NUMBER,
  PICKUP_SERVICE_HELP,
  BOTH_SERVICE_HELP,
  SERVICE_CONFLICT,
  CANCEL_ORDER_CONFIRM,
  HOW_IT_WORKS,
  SCAN_TRACKING_NUMBER,
}

export enum TransitionDirection {
  FWD = 'fwd',
  BACK = 'back',
  FWD_TO_LARGE_PAGE = 'fwd-to-large-page',
  BACK_FROM_LARGE_PAGE = 'back-from-large-page',
  FWD_FROM_LARGE_PAGE = 'fwd-from-large-page',
  BACK_TO_LARGE_PAGE = 'back-to-large-page',
}

export enum PageHeight {
  SMALL = 'small',
  LARGE = 'large',
}

export enum NavigationDirection {
  FORWARD = 'forward',
  BACK = 'back',
}

export type TransitionTypeMap = {
  [key in PageHeight]: {
    [key in PageHeight]: {
      [key in NavigationDirection]: TransitionDirection
    }
  }
}

export enum GTMEvent {
  NUMBER_OF_PACKAGES = 'number_of_packages',
  PURCHASE = 'purchase',
}

export interface VerifyTrackingNumberResponse {
  allow_override?: number
  carrier: string
  date_created?: string
  destination?: {
    city: string
    country: string
    postal_code?: string
    state_province: string
  }
  error?: string
  pickup_location?: {
    city: string
    country: string
    postal_code?: string
    state_province: string
  }
  result: ApiResult
}

export interface VerifyTrackingNumberResponseError {
  response: {
    data: VerifyTrackingNumberResponse
  }
}

export type PriceMap = { [k: number]: number }

export enum AllPricesRequired {
  YES = 'yes',
  NO = 'no',
}

export enum FunctionFlag {
  CALL_TAGS = 'CT52',
  LABEL_SCANNER = 'LS78',
  // PAYMENT_INFO = 'PI66',
}

export interface CostBreakdownDetail {
  quantity: number
  totalCost: number
}

export interface CostBreakdown {
  callTags: CostBreakdownDetail
  labels: CostBreakdownDetail
  pickups: CostBreakdownDetail
}
