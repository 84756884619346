import React from 'react'
import classnames from 'classnames'

interface Props {
  className?: string
}

export const Card: React.FC<Props> = ({ children, className }) => (
  <div className={classnames('PrimaryLayout-card', className)}>{children}</div>
)
