import React from 'react'
import { useSelector } from 'react-redux'

import Loader from '../../components/Loader'
import Banner from '../../components/Banner'
import { BasicLayout } from './BasicLayout'
import PrivacyPolicyModal from '../../modals/PrivacyPolicyModal'
import { RouteTransition } from '../../components/RouteTransition'
import { selectors } from '../../redux/settings'
import TermsAndConditionsModal from '../../modals/TermsAndConditionsModal'

const BasicLayoutContainer: React.FC = ({ children }) => {
  const menuItems = useSelector(selectors.getMenuItems)

  return (
    <>
      <BasicLayout menuItems={menuItems}>{children}</BasicLayout>

      <RouteTransition />

      <Banner />

      <Loader />

      <TermsAndConditionsModal />

      <PrivacyPolicyModal />
    </>
  )
}

export default BasicLayoutContainer
