import { Address, VerifedAddressState } from '../../types'

const addressKeys: Array<keyof Address> = [
  'address_id',
  'address1',
  'address2',
  'city',
  'postal_code',
  'residence',
  'state_province',
]

const stringify = (address: Address): string =>
  addressKeys
    .map((addressKey) => address[addressKey])
    .join('')
    .replace(/\s/g, '')
    .toLowerCase()

const verifiedAddress = (
  address: Address,
  verifiedAddresses: VerifedAddressState,
): Address | undefined => verifiedAddresses[stringify(address)]

export const AddressModel = {
  stringify,
  verifiedAddress,
}
