import React from 'react'

type ScrollState = {
  isScrolled: boolean
}

export const ScrollContext = React.createContext<ScrollState | undefined>(
  undefined,
)

export const ScrollContextProvider: React.FC = ({ children }) => {
  const [isScrolled, setIsScrolled] = React.useState(false)

  const ticking = React.useRef(false)

  React.useEffect(() => {
    const handleScroll = () => {
      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          setIsScrolled(Boolean(window.scrollY))
          ticking.current = false
        })

        ticking.current = true
      }
    }

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <ScrollContext.Provider value={{ isScrolled }}>
      {children}
    </ScrollContext.Provider>
  )
}
