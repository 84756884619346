import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch } from '../../types'
import { load, selectors } from '../../redux/settings'
import { DataGuard } from './DataGuard'

const DataGuardContainer: React.FC = ({ children }) => {
  const settings = useSelector(selectors.getSettings)
  const dispatch = useDispatch<AppDispatch>()
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    dispatch(load()).finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DataGuard loading={loading} settings={settings}>
      {children}
    </DataGuard>
  )
}

export default DataGuardContainer
